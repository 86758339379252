@import '/app/src/shared/theme/mixins.scss'; @import '/app/src/shared/theme/var.scss'; @import '/app/src/theme/var.scss';
.container {
    background-image: url('/static/images/LoginBg.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    .header {
        display: flex;
        align-items: center;
        justify-content: center;

        @include respond-below(lg) {
            display: none;
        }

        .create {
            margin: 0 30px;
            color: $text-dark;
            font-weight: 500;
            font-size: 18px;
        }
    }

    .layer {
        max-width: 340px;
        min-height: 500px;
        margin: 0 auto;

        @include title($text-dark);
    }

    .review {
        p {
            color: $text-dark !important;
        }
    }

    input {
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
            border: none;
            -webkit-text-fill-color: white !important;
        }
    }
}
